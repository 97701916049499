<template>
	<div class="navigator-header is-fixed-top">
		<router-link :to="{ name: 'Home' }" class="logo is-unselectable">
			<img src="@/assets/logo-full-white.svg" />
		</router-link>
		<div class="separator"></div>
		<div class="navigator-tabs-wrapper" @dblclick.prevent.self="canAddNewTab">
			<div class="navigator-tabs">
				<div
					@click.prevent="setActiveTab(index)"
					class="navigator-tab fade-in-right"
					v-for="(tab, index) in tabConfig"
					:key="tab"
					:class="{ 'is-active': activeTab === index }"
					:id="tab.id"
				>
					<div class="navigator-tab-title">
						<input
							:ref="`input-${tab.id}`"
							:size="tab.title.replace(/  +/g, ' ').length || 1"
							type="text"
							spellcheck="false"
							class="is-ellipsis"
							v-model.trim="tab.title"
							@keyup.enter="setTabFocus($event, tab, index)"
						/>
					</div>
					<div
						class="navigator-tab-icon close"
						@click.stop.prevent="closeTab(index)"
						:class="[{ 'is-unselectable': tabConfig.length === 1 }]"
						v-if="!tab.isLoading"
					>
						<icon icon="times" />
					</div>
					<div class="navigator-tab-icon icon" v-if="tab.isLoading === true">
						<span class="loader is-dark"></span>
					</div>
				</div>
			</div>
			<div class="add-new-tab" @click="canAddNewTab">
				<icon icon="plus" />
			</div>
			<div class="user-profile" v-if="isLoggedIn" @click="signout">Logout</div>
		</div>
	</div>
</template>

<script>
import axios from "axios"
import { mapActions, mapGetters } from "vuex"
export default {
	methods: {
		...mapActions(["addNewTab", "closeTab", "setActiveTab", "signout"]),
		canAddNewTab() {
			let { tabConfig, addNewTab, setActiveTab } = this
			addNewTab(true).then(() => {
				this.$nextTick(() => setActiveTab())
				this.$nextTick(() => {
					let { activeTab } = this
					let { id } = tabConfig[activeTab]
					let doc = document.getElementById(id)
					doc.scrollIntoView({ behavior: "smooth", block: "center" })
				})
			})
		},
		setTabFocus(event, tab, index) {
			let refID = `input-${tab.id}`

			if (event.key !== "Enter") return

			this.setActiveTab(index)
			this.$emit("set-tab-focus", tab)
			this.$nextTick(() => this.$refs[refID][0].blur())
			this.$nextTick(() => {
				this.syncLayerChanges({ tab })
			})
		},
		syncLayerChanges({ tab }) {
			if (!tab.active) return

			let layerID = tab.id
			let url = `/user/layers/${layerID}/update`
			let payload = {
				layer: { title: tab.title }
			}

			axios.put(url, payload)
			// .then(response => {})
		}
	},
	computed: {
		...mapGetters(["tabConfig", "activeTab", "isLoggedIn"])
	}
}
</script>

<style lang="scss" scoped>
$header-height: $navigator-header-height;
$header-min-height: $navigator-header-height-min;
$gap: 0.5rem;

.navigator-header {
	position: relative;
	z-index: 5;
	height: $navigator-header-height;
	max-height: $navigator-header-height;
	min-height: $navigator-header-height;
	width: 100%;
	padding: 0 $gap;
	background-color: $base-background-color;

	display: flex;
	align-items: center;
	gap: 0.5rem;
	border-bottom: 1px solid $base-background-color-light;
}

.logo {
	padding: 0 1rem;
	height: inherit;
	display: flex;
	align-items: center;
	height: 100%;

	&:hover {
		background-color: $base-hover-background-color;
		cursor: pointer;
	}

	img {
		max-height: calc($navigator-header-height-min - 8px);
		min-height: calc($navigator-header-height-min - 6px);
	}
}

.separator {
	height: 50%;
	width: 1px;
	min-width: 1px;
	background-color: $base-active-background-color-light;
}

// navigator-Tabs styling
.navigator-tabs-wrapper {
	display: flex;
	gap: $gap;
	height: 100%;
	width: 100%;
	align-items: center;
	overflow: hidden;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;

	.navigator-tab {
		color: $base-text-color;
		display: flex;
		gap: $gap;
		align-items: center;
		justify-content: space-between;
		height: 100%;
		padding: 0 calc($gap * 2);
		// transition: background-color 50ms ease-in-out;
		position: relative;

		&:not(:first-child, .is-active, :hover)::before,
		&:last-child::after {
			content: "";
			position: absolute;
			width: 1px;
			height: 50%;
			min-width: 1px;
			align-self: center;
			background-color: $base-active-background-color-light;
		}

		&:not(:first-child, .is-active, :hover)::before {
			left: 0;
		}

		&:first-child::after {
			background-color: transparent;
		}

		&:last-child::after {
			right: 0px !important;
		}

		// hide separator on active and hovered tabs items
		&.is-active + .navigator-tab::before {
			background-color: transparent;
		}

		&:hover + .navigator-tab::before {
			background-color: transparent;
		}

		&:hover {
			cursor: auto; // grabbable
			background-color: $base-hover-background-color;

			&::after {
				background-color: transparent;
			}
		}

		&.is-active {
			border-top: 2px solid $primary;
			background-color: $base-active-background-color;
			color: $base-active-text-color;

			input,
			input:hover {
				color: $base-active-text-color !important;
			}

			&::after {
				background-color: transparent;
			}
		}

		input {
			color: $base-text-color-light;
		}

		.navigator-tab-icon {
			display: flex;
			justify-content: center;
			align-items: center;
			height: calc($header-height/2);
			width: calc($header-height/2);
			cursor: auto;
		}

		.navigator-tab-title {
			overflow: hidden;

			input {
				min-width: 50px;
				max-width: 125px;
				background: transparent;
				box-shadow: none;
				outline: none;
				border: none;

				&:hover,
				&:focus {
					transition: color 200ms ease-in;
				}

				&:hover {
					color: $base-hover-text-color;
				}

				&:focus {
					color: $base-active-text-color;
					border-bottom: 1px dotted $base-text-color;
				}
			}
		}

		.navigator-tab-icon.close {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100px;

			&:hover {
				cursor: pointer;
				background-color: $base-active-background-color-light;
				color: $base-active-text-color-light;
			}
		}

		.navigator-tab-title {
			input {
				min-width: 50px;
				max-width: 125px;
				background: transparent;
				box-shadow: none;
				outline: none;
				border: none;

				&:hover,
				&:focus {
					transition: color 200ms ease-in;
				}

				&:hover {
					color: $base-hover-text-color;
				}

				&:focus {
					color: $base-active-text-color;
					border-bottom: 1px dotted $base-text-color;
				}
			}
		}
	}
}

.navigator-tabs {
	display: flex;
	height: 100%;
	width: fit-content;
	overflow: auto;
	flex: 1;

	.navigator-tab {
		height: 100%;
		width: fit-content;
		min-width: 110px;
		padding: 0 calc($gap) 0 calc($gap * 2);
		display: flex;
		align-items: center;
		justify-content: space-between;

		&.is-active {
			background-color: $base-background-color-light;
			color: $base-text-color-light;
			border-top: 3px solid $base-active-text-color;
		}

		.navigator-tab-title,
		.navigator-tab-icon {
			cursor: pointer;
			color: $base-hover-text-color;

			&:hover {
				color: $base-hover-text-color-light;
			}

			&.close {
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 100px;

				&:hover {
					cursor: pointer;
					background-color: $base-active-background-color-light;
					color: $base-active-text-color-light;
				}
			}
		}
	}
}

.add-new-tab {
	display: flex;
	align-items: center;
	justify-content: center;
	width: $header-min-height;
	height: $header-min-height;
	min-width: $header-min-height;
	padding: calc($gap * 2);
	border: 0;
	outline: 0;
	border-radius: 100px;
	box-shadow: unset !important;
	color: $base-hover-text-color;
	background-color: $base-background-color-light;
	transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

	&:hover {
		cursor: pointer;
		background-color: $base-hover-background-color;
		color: $base-hover-text-color-light;
	}
}

.user-profile {
	display: flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
	height: $header-min-height;
	min-width: $header-min-height;
	padding: calc($gap * 2);
	border: 0;
	outline: 0;
	border-radius: $gap;
	box-shadow: unset !important;
	color: $base-hover-text-color;
	background-color: $base-background-color-light;
	transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

	&:hover {
		cursor: pointer;
		background-color: $base-hover-background-color;
		color: $base-hover-text-color-light;
	}
}

/* scrollbar */
::-webkit-scrollbar {
	height: 0px;
}
</style>
